import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [cookieCount, setCookieCount] = useState(0);
  const [newCookies, setNewCookies] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [cookieType, setCookieType] = useState('');
  const [customType, setCustomType] = useState('');
  const [cookieTypes, setCookieTypes] = useState([]);
  const [cityStateCounts, setCityStateCounts] = useState([]);
  const [yearlyCounts, setYearlyCounts] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [locations, setLocations] = useState([]);
  const [expandedPhoto, setExpandedPhoto] = useState(null);
  const [cookieStats, setCookieStats] = useState({});


  const backendURL = 'https://cookiecounter-backend.onrender.com';

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'Other',
  ];

  const countries = [
    'United States', 'Canada', 'Mexico', 'United Kingdom', 'Germany',
    'France', 'Italy', 'Spain', 'India', 'China', 'Japan', 'Australia',
    'Brazil', 'South Africa', 'Russia', 'South Korea', 'New Zealand',
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/get-cookies`);
        const data = await response.json();
        const fetchedLocations = data.locations || [];
  
        setCookieCount(
          fetchedLocations.reduce((sum, loc) => sum + loc.cookies, 0)
        );
        setCookieTypes(data.types.map((type) => type.type));
        setLocations(fetchedLocations);
  
        groupByCityState(fetchedLocations);
        groupByYear(fetchedLocations);
  
        // Calculate cookie counts by type
        const stats = fetchedLocations.reduce((acc, loc) => {
          const type = loc.cookieType;
          acc[type] = (acc[type] || 0) + loc.cookies;
          return acc;
        }, {});
        setCookieStats(stats);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [backendURL]);

  const groupByCityState = (locations) => {
    const counts = {};
    locations.forEach(({ city, state, cookies }) => {
      const key = `${city}, ${state}`;
      counts[key] = (counts[key] || 0) + cookies;
    });
    setCityStateCounts(
      Object.entries(counts)
        .map(([key, count]) => ({ key, count }))
        .sort((a, b) => b.count - a.count)
    );
  };

  const groupByYear = (locations) => {
    const counts = {};
    locations.forEach(({ cookies, timestamp }) => {
      const year = new Date(timestamp).getFullYear();
      counts[year] = (counts[year] || 0) + cookies;
    });
    setYearlyCounts(
      Object.entries(counts)
        .map(([year, count]) => ({ year, count }))
        .sort((a, b) => b.year - a.year)
    );
  };

  const addCookies = async () => {
    if (!newCookies || isNaN(newCookies) || Number(newCookies) <= 0) {
      alert('Please enter a valid number of cookies.');
      return;
    }
    if (!city || !state || !country || (!cookieType && !customType)) {
      alert('Please complete all fields.');
      return;
    }

    const typeToAdd = cookieType === 'Other' ? customType : cookieType;

    const formData = new FormData();
    formData.append('cookies', newCookies);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('cookieType', typeToAdd);
    if (photo) formData.append('photo', photo);

    try {
      const response = await fetch(`${backendURL}/add-cookies`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      const updatedLocations = data.locations || [];
      setLocations(updatedLocations);
      setCookieCount(
        updatedLocations.reduce((sum, loc) => sum + loc.cookies, 0)
      );

      if (cookieType === 'Other' && !cookieTypes.includes(customType)) {
        setCookieTypes([...cookieTypes, customType]);
      }

      groupByCityState(updatedLocations);
      groupByYear(updatedLocations);
      resetForm();
    } catch (error) {
      console.error('Error adding cookies:', error);
    }
  };

  const resetForm = () => {
    setNewCookies('');
    setCity('');
    setState('');
    setCountry('');
    setCookieType('');
    setCustomType('');
    setPhoto(null);
  };

  const expandPhoto = (photoUrl) => {
    setExpandedPhoto(photoUrl);
  };
  
  const closePhoto = () => {
    setExpandedPhoto(null);
  };

  return (
    <div className="App">
    <header className="festive-banner">
        <h1>Cookie Baking Day Cookie Counter</h1>
        <div className="flashing-lights">
          {/* Flashing lights */}
          <div className="light red"></div>
          <div className="light green"></div>
          <div className="light yellow"></div>
          <div className="light blue"></div>
        </div>
      </header>
      <main>
        <h2>Total Cookies Baked: <span className="count">{cookieCount}</span></h2>

        <div className="actions">
          <input
            type="number"
            placeholder="Number of cookies"
            value={newCookies}
            onChange={(e) => setNewCookies(e.target.value)}
          />
          <input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="" disabled>Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
          <select value={country} onChange={(e) => setCountry(e.target.value)}>
            <option value="" disabled>Select Country</option>
            {countries.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
          <select value={cookieType} onChange={(e) => setCookieType(e.target.value)}>
            <option value="" disabled>Select Cookie Type</option>
            {cookieTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
            <option value="Other">Other</option>
          </select>
          {cookieType === 'Other' && (
            <input
              type="text"
              placeholder="Custom Cookie Type"
              value={customType}
              onChange={(e) => setCustomType(e.target.value)}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setPhoto(e.target.files[0])}
          />
          <button onClick={addCookies}>Add Cookies</button>
        </div>

        <section>
  <h3>Cookie Counts by Type</h3>
  <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>Cookie Type</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(cookieStats).map(([type, count], index) => (
          <tr key={index}>
            <td>{type}</td>
            <td>{count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</section>

<section>
  <h3>Cookie Counts by Location</h3>
  <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>City</th>
          <th>State</th>
          <th>Total Cookies</th>
        </tr>
      </thead>
      <tbody>
        {cityStateCounts.map((item, index) => (
          <tr key={index}>
            <td>{item.key.split(', ')[0]}</td>
            <td>{item.key.split(', ')[1]}</td>
            <td>{item.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</section>

<section>
  <h3>Cookie Counts by Year</h3>
  <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>Year</th>
          <th>Total Cookies</th>
        </tr>
      </thead>
      <tbody>
        {yearlyCounts.map((item, index) => (
          <tr key={index}>
            <td>{item.year}</td>
            <td>{item.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</section>

        <section>
          <h3>Cookie Log</h3>
          <table>
            <thead>
              <tr>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Type</th>
                <th>Count</th>
                <th>Photo</th>
              </tr>
            </thead>
            <tbody>
              {locations.map((log, index) => (
                <tr key={index}>
                  <td>{log.city}</td>
                  <td>{log.state}</td>
                  <td>{log.country}</td>
                  <td>{log.cookieType}</td>
                  <td>{log.cookies}</td>
                  <td>
  {log.photo ? (
    <img
      src={log.photo}
      alt="Cookie"
      style={{ maxWidth: '100px', cursor: 'pointer' }}
      onClick={() => expandPhoto(log.photo)}
    />
  ) : (
    'No Photo'
  )}
</td>

                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>

  {expandedPhoto && (
  <div className="photo-modal" onClick={closePhoto}>
    <div className="photo-modal-content">
      <img src={expandedPhoto} alt="Expanded Cookie" />
    </div>
  </div>
)}

      <footer>
        <p>Track cookies baked worldwide!</p>
      </footer>
    </div>
  );
}

export default App;
